import React, { useEffect, useState } from 'react';

const SummerEffect = () => {
  const [sunRays, setSunRays] = useState([]);
  const [bubbles, setBubbles] = useState([]);
  
  useEffect(() => {
    // Güneş ışınları oluştur
    const createSunRay = () => ({
      id: Math.random().toString(),
      rotation: Math.random() * 360,
      width: 1 + Math.random() * 2,
      opacity: 0.2 + Math.random() * 0.3,
      animationDuration: 4 + Math.random() * 3,
      hue: Math.floor(30 + Math.random() * 20) // Altın sarısı ton
    });
    
    // Hava kabarcıkları/parıltılar oluştur
    const createBubble = () => ({
      id: Math.random().toString(),
      left: Math.random() * 100,
      top: Math.random() * 100,
      size: 3 + Math.random() * 10,
      opacity: 0.1 + Math.random() * 0.3,
      animationDuration: 5 + Math.random() * 10,
      hue: Math.floor(180 + Math.random() * 40) // Mavi-turkuaz ton
    });

    // Başlangıç güneş ışınları
    setSunRays(Array.from({ length: 20 }, createSunRay));
    
    // Başlangıç parıltılar
    setBubbles(Array.from({ length: 30 }, createBubble));
    
    // Periyodik olarak yeni parıltılar ekle
    const interval = setInterval(() => {
      setBubbles(prev => {
        if (prev.length > 40) return [...prev.slice(-39), createBubble()];
        return [...prev, createBubble()];
      });
    }, 800);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      {/* Güneş */}
      <div 
        className="absolute z-40"
        style={{
          top: '5%',
          right: '10%',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          background: 'radial-gradient(circle, rgba(255,236,139,1) 0%, rgba(255,165,0,1) 90%)',
          boxShadow: '0 0 30px rgba(255, 200, 0, 0.7)',
          animation: 'pulse 5s infinite alternate'
        }}
      >
        {/* Güneş Işınları */}
        {sunRays.map(ray => (
          <div
            key={ray.id}
            className="absolute origin-bottom"
            style={{
              bottom: '50%',
              left: '50%',
              height: '120px',
              width: `${ray.width}px`,
              backgroundColor: `hsla(${ray.hue}, 100%, 60%, ${ray.opacity})`,
              transform: `translateX(-50%) rotate(${ray.rotation}deg)`,
              animation: `sunrayGrow ${ray.animationDuration}s infinite alternate`
            }}
          />
        ))}
      </div>

      {/* Parıltılar/kabarcıklar */}
      {bubbles.map(bubble => (
        <div
          key={bubble.id}
          className="absolute rounded-full"
          style={{
            left: `${bubble.left}%`,
            top: `${bubble.top}%`,
            width: `${bubble.size}px`,
            height: `${bubble.size}px`,
            background: `radial-gradient(circle, hsla(${bubble.hue}, 100%, 75%, ${bubble.opacity + 0.2}) 0%, hsla(${bubble.hue}, 100%, 65%, ${bubble.opacity}) 100%)`,
            animation: `float ${bubble.animationDuration}s infinite ease-in-out, 
                        sparkle ${bubble.animationDuration / 2}s infinite alternate`
          }}
        />
      ))}
      
      {/* CSS Animasyonlar */}
      <style>{`
        @keyframes pulse {
          0% { transform: scale(1); box-shadow: 0 0 30px rgba(255, 200, 0, 0.7); }
          100% { transform: scale(1.05); box-shadow: 0 0 50px rgba(255, 200, 0, 0.9); }
        }
        
        @keyframes sunrayGrow {
          0% { height: 120px; opacity: 0.4; }
          100% { height: 150px; opacity: 0.8; }
        }
        
        @keyframes float {
          0%, 100% { transform: translateY(0) scale(1); }
          50% { transform: translateY(-20px) scale(1.1); }
        }
        
        @keyframes sparkle {
          0% { opacity: 0.2; }
          100% { opacity: 0.8; }
        }
      `}</style>
    </div>
  );
};

export default SummerEffect;